import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Button } from '@mui/material';
import dayjs from 'dayjs';
import '../App.css';
import { getTodayBookings } from '../api';

const GuestHomePage = ({ user, setUser, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [todayBookings, setTodayBookings] = useState([]);

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
    setIsLoggedIn(false);
    navigate('/');
  };

  useEffect(() => {
    const getBookings = async () => {
      try {
        const bookings = await getTodayBookings(user.token);

        const currentDate = new Date().toISOString().split('T')[0];
        const filteredBookings = bookings.filter((booking) => booking.date === currentDate);

        // Sort bookings by date and time (newest first)
        filteredBookings.sort((a, b) => new Date(b.date + ' ' + b.time) - new Date(a.date + ' ' + a.time));

        setTodayBookings(filteredBookings);
      } catch (error) {
        console.error('Error fetching bookings:', error.response?.data || error.message);
      }
    };

    if (user.token) {
      getBookings();
    }
  }, [user.token]);

  // Render a single booking card
  const renderBookingCard = (booking) => (
    <Card variant="outlined" sx={{ marginBottom: 2, padding: 1 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" color="textSecondary">In/Out</Typography>
            <Typography>{booking.booking_type}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant="subtitle2" color="textSecondary">Date</Typography>
            <Typography>{dayjs(booking.date).format('YY-MM-DD')}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle2" color="textSecondary">Time</Typography>
            <Typography>{booking.time}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant="subtitle2" color="textSecondary">A/C Type</Typography>
            <Typography>{booking.aircraft_type}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" color="textSecondary">Registration</Typography>
            <Typography>{booking.registration}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" color="textSecondary">From / To</Typography>
            <Typography>{booking.location}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" color="textSecondary">Pilot</Typography>
            <Typography>{booking.pilot}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle2" color="textSecondary">Pax</Typography>
            <Typography>{booking.pax}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <div className="home-page-container">
      <div className="logo-container">
        <img src="/popham-logo.png" alt="Popham Aircraft Booking Logo" className="logo" />
      </div>
      <h1 className="home-page-title">Welcome Guest</h1>
      <div className="landscape-notice">
        <p>For the best experience, please rotate your device to landscape mode.</p>
      </div>
      <div className="home-button-group">
        <Button variant="contained" color="primary" onClick={() => navigate('/book-in')}>
          Book In
        </Button>
        <Button variant="contained" color="primary" onClick={() => navigate('/book-out')}>
          Book Out
        </Button>
        <Button variant="contained" color="error" onClick={handleLogout}>
          Logout
        </Button>
      </div>

      <div className="home-table-container">
        <h2>Today's Bookings</h2>
        {todayBookings.map((booking, index) => (
          <React.Fragment key={index}>{renderBookingCard(booking)}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default GuestHomePage;
