import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Button, TextField } from '@mui/material';
import { fetchBookings, getTodayBookings } from '../api';
import dayjs from 'dayjs';

const HomePage = ({ user, setUser, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [todayBookings, setTodayBookings] = useState([]);
  const [userHistory, setUserHistory] = useState([]);
  const [search, setSearch] = useState('');

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
    setIsLoggedIn(false);
    navigate('/');
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  useEffect(() => {
    const getBookings = async () => {
      try {
        const bookings = await fetchBookings(user.token);
        const todayBookings = await getTodayBookings(user.token);

        // Sort by latest date and time
        const sortByLatest = (a, b) => new Date(b.date + ' ' + b.time) - new Date(a.date + ' ' + a.time);

        setTodayBookings(todayBookings.sort(sortByLatest));
        setUserHistory(bookings.sort(sortByLatest).slice(0, 10)); // Last 10 bookings
      } catch (error) {
        console.error('Error fetching bookings:', error.response?.data || error.message);
      }
    };

    if (user.token) {
      getBookings();
    }
  }, [user.token]);


    const renderBookingCard = (booking) => (
      <Card variant="outlined" sx={{ marginBottom: 1, padding: 1 }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <Typography variant="subtitle2" color="textSecondary">In/Out</Typography>
              <Typography>{booking.booking_type}</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography variant="subtitle2" color="textSecondary">Date</Typography>
              <Typography>{dayjs(booking.date).format('YY-MM-DD')}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="subtitle2" color="textSecondary">Time</Typography>
              <Typography>{booking.time}</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography variant="subtitle2" color="textSecondary">A/C Type</Typography>
              <Typography>{booking.aircraft_type}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" color="textSecondary">Registration</Typography>
              <Typography>{booking.registration}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" color="textSecondary">From / To</Typography>
              <Typography>{booking.location}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" color="textSecondary">Pilot</Typography>
              <Typography>{booking.pilot}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="subtitle2" color="textSecondary">Pax</Typography>
              <Typography>{booking.pax}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );

  return (
    <div className="home-page-container">
      <div className="logo-container">
        <img src="/popham-logo.png" alt="Popham Aircraft Booking Logo" className="logo" />
      </div>
      <h1 className="home-page-title">Welcome, {user.username}</h1>

      <div className="landscape-notice">
        <p>For the best experience, please rotate your device to landscape mode.</p>
      </div>

      <div className="home-button-group">
        <Button variant="contained" color="primary" onClick={() => navigate('/book-in')}>
          Book In
        </Button>
        <Button variant="contained" color="primary" onClick={() => navigate('/book-out')}>
          Book Out
        </Button>
        <Button variant="contained" color="success" onClick={() => navigate('/account')}>
          Account
        </Button>
        <Button variant="contained" color="error" onClick={handleLogout}>
          Logout
        </Button>
      </div>

      {/* Today's Bookings */}
      <div className="home-table-container">
        <h2>Today's Bookings</h2>
        {todayBookings.map((booking, index) => (
          <React.Fragment key={index}>{renderBookingCard(booking)}</React.Fragment>
        ))}
      </div>

      {/* User Booking History */}
      <div className="home-table-container">
        <h2>Your Booking History</h2>
        <TextField
          id="search"
          label="Search Bookings"
          variant="outlined"
          fullWidth
          margin="normal"
          value={search}
          onChange={handleSearchChange}
        />
        {userHistory
          .filter(
            (booking) =>
              booking.date.toLowerCase().includes(search) ||
              booking.registration.toLowerCase().includes(search)
          )
          .map((booking, index) => (
            <React.Fragment key={index}>{renderBookingCard(booking)}</React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default HomePage;
