import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserData, createBooking } from '../api'; // Import API functions
import '../App.css';

const BookInPage = ({ user }) => {
  const [registration, setRegistration] = useState('');
  const [aircraft_type, setType] = useState('');
  const [pilot, setPilot] = useState('');
  const [pax, setPax] = useState(1);
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Defaults to today's date
  const [time, setTime] = useState(new Date().toISOString().split('T')[1].substring(0, 5)); // Defaults to current time
  const [location, setLocation] = useState('');

  const navigate = useNavigate();

  // Fetch user data on mount if user is not a guest or admin
  useEffect(() => {
    if (user && user.token && user.role !== 'guest' && user.role !== 'admin') {
      const fetchUserData = async () => {
        try {
          const data = await getUserData(user.token); // Fetch user data using API function
          setPilot(`${data.last_name || ''}`.trim());
          setRegistration(data.registration?.toUpperCase() || '');
          setType(data.aircraft_type || '');
        } catch (error) {
          console.error('Error fetching user data', error);
          alert('Failed to fetch user data');
        }
      };

      fetchUserData();
    } else {
      // If user is a guest or admin, set the form fields to default or empty values
      setPilot('');
      setRegistration('');
      setType('');
    }
  }, [user]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const bookingData = {
      registration,
      aircraft_type,
      pilot,
      pax,
      location,
      date,
      time,
      booking_type: 'In', // Specify booking type as 'in'
    };

    try {
      const response = await createBooking(bookingData, user.token); // Submit booking using API function

      if (response.status === 201) {
        if (user.role === 'admin') {
          navigate('/admin-home');
        } else if (user.role === 'guest') {
          navigate('/guest-home');
        } else {
          navigate('/home'); // This is for non-admin, non-guest users
        }
      } else {
        alert('Booking failed: Unexpected response');
      }
    } catch (error) {
      console.error('Error making booking:', error);
      alert('Booking failed: ' + (error.response ? error.response.data : error.message));
    }
  };

  // Handle cancel action
  const handleCancel = () => {
    navigate('/home'); // Go back to home page without making a booking
  };

  return (
<div className="bookin-container">
  {/* Add your logo at the top */}
  <h1>Book In</h1>
  <form onSubmit={handleSubmit} className="bookin-form">
    <div className="form-group">
      <input
        type="text"
        value={registration}
        onChange={(e) => setRegistration(e.target.value.toUpperCase())}
        placeholder="Registration"
        required
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        value={aircraft_type}
        onChange={(e) => setType(e.target.value)}
        placeholder="Aircraft Type"
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        value={pilot}
        onChange={(e) => setPilot(e.target.value)}
        placeholder="Pilot"
        required
      />
    </div>
    <div className="form-group">
      <input
        type="number"
        value={pax}
        onChange={(e) => setPax(Number(e.target.value))}
        placeholder="No Passengers including pilot"
        required
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        placeholder="From"
        required
      />
    </div>
    <div className="form-group">
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        required
      />
    </div>
    <div className="form-group">
      <input
        type="time"
        value={time}
        onChange={(e) => setTime(e.target.value)}
        required
      />
    </div>
    {/* Buttons aligned at the bottom */}
    <div className="form-buttons">
      <button type="button" onClick={handleCancel} className="secondary-btn">
        Cancel
      </button>
      <button type="submit" className="primary-btn">
        Book In
      </button>
    </div>
  </form>
</div>

  );
};

export default BookInPage;
