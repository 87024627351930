import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { loginUser } from '../api';  // Import the function from api.js
import '../App.css';

const LoginPage = ({ setIsLoggedIn, setUser }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await loginUser(username, password);
      const token = data.access_token;




      // Decode the token to get the identity (which contains username and role)
      const decodedToken = jwtDecode(token);
      console.log('Decoded Token:', decodedToken); // To inspect the structure of the decoded token
      
      // Extract the username from 'sub' and the role directly from the root of the token
      const decodedUsername = decodedToken.sub;  // 'sub' contains the username
      const role = decodedToken.role;  // 'role' is a direct field in the token

      console.log("Decoded username:", decodedUsername);
      console.log("Decoded role:", role);  // Check if the role is now correct

      // Set the user data to local storage and state
      localStorage.setItem('user', JSON.stringify({ username: decodedUsername, token, role }));
      setUser({ username: decodedUsername, token, role });
      setIsLoggedIn(true);
      console.log('Logged in');
  
      // Navigate based on the role
      if (role === 'admin') {
        navigate('/admin-home');
      } else if (role === 'guest') {
        navigate('/guest-home');
      } else {
        console.log('going to /home');
        navigate('/home');
      }
    } catch (error) {
      alert('Login failed');
    }
  };
  

  return (
<div className="landing-container">
  <div className="overlay">
    <div className="content">
      <div className="logo-container">
        <img src="/popham-logo.png" alt="Aircraft Booking System Logo" className="logo" />
      </div>
      <h1>Popham Aircraft Booking</h1>
      <Link to="/register" className="register-link">Don't have an account? Register here!</Link>
      <h2>Login</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="email"
          placeholder="Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className="input-field"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="input-field"
        />
        <button type="submit" className="submit-button">Login</button>
      </form>
    </div>
  </div>
</div>


  );
};

export default LoginPage;
