import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserData, updateUserAccount } from '../api'; // Import API functions
import '../App.css'; // Import custom CSS

const AccountPage = ({ user }) => {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [aircraft_type, setType] = useState('');
  const [registration, setRegistration] = useState('');
  const [membershipNumber, setMembershipNumber] = useState('');
  const [isCurrentMember, setIsCurrentMember] = useState(true);  // Default to true

  const navigate = useNavigate();

  // Fetch user data when component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await getUserData(user.token); // Fetch user data using the API function
        setFirstName(data.first_name || '');
        setLastName(data.last_name || '');
        setType(data.aircraft_type || '');
        setRegistration(data.registration || '');
        setMembershipNumber(data.membership_number || '');
        setIsCurrentMember(data.is_current_member || true);
      } catch (error) {
        console.error('Error fetching user data', error);
        alert('Failed to fetch user data');
      }
    };

    if (user && user.token) {
      fetchUserData();
    }
  }, [user]);

  // Handle save action
  const handleSave = async () => {
    const userData = {
      first_name,
      last_name,
      aircraft_type,
      registration,
      membership_number: membershipNumber,
      is_current_member: isCurrentMember,
    };

    try {
      await updateUserAccount(userData, user.token); // Update user data using API function
      navigate('/home');
    } catch (error) {
      alert('Error updating account: ' + error.message);
    }
  };

  // Handle cancel action
  const handleCancel = () => {
    navigate('/home'); // Go back to the home page without saving changes
  };

  return (
    <div className="account-container">
    {/* Add your logo at the top */}
    <div className="logo-container">
      <img src="/popham-logo.png" alt="Logo" className="logo" />
    </div>
    <h1>Account Details</h1>
    <form className="account-form">
      <div className="form-group">
        <input
          type="text"
          value={first_name}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="First Name"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          value={last_name}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Last Name"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          value={aircraft_type}
          onChange={(e) => setType(e.target.value)}
          placeholder="Aircraft Type"
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          value={registration}
          onChange={(e) => setRegistration(e.target.value.toUpperCase())}
          placeholder="Aircraft Registration"
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          value={membershipNumber}
          onChange={(e) => setMembershipNumber(e.target.value)}
          placeholder="Membership Number"
        />
      </div>
      <div className="form-group-checkbox">
        <label className="checkbox-label">
          <input
            type="checkbox"
            checked={isCurrentMember}
            onChange={() => setIsCurrentMember(!isCurrentMember)}
          />
          Current Member
        </label>
      </div>
      <div className="form-buttons">
        <button type="button" className="primary-btn" onClick={handleSave}>
          Save
        </button>
        <button type="button" onClick={handleCancel} className="secondary-btn">
          Cancel
        </button>
      </div>
    </form>
  </div>
  
  );
};

export default AccountPage;
