import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../api';  // Import the registerUser function
import '../App.css';

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    aircraft_type: '',
    registration: '',
    membership_number: '',
    is_current_member: false,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Call the registerUser function from api.js
      await registerUser(formData);
      alert('Registration successful');
      navigate('/login');
    } catch (error) {
      console.error('Error during registration:', error);
      alert('Registration failed');
    }
  };

  return (
    <div className="register-container">
      <h1>Register</h1>
      <form onSubmit={handleSubmit} className="register-form">
        <div className="form-group">
          <label>Username:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input type="password" name="password" value={formData.password} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>First Name:</label>
          <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Last Name:</label>
          <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Aicrraft Type:</label>
          <input type="text" name="aircraft_type" value={formData.aircraft_type} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Registration:</label>
          <input type="text" name="registration" value={formData.registration} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Membership Number:</label>
          <input type="text" name="membership_number" value={formData.membership_number} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>
            <input type="checkbox" name="is_current_member" checked={formData.is_current_member} onChange={handleChange} />
            Current Member
          </label>
        </div>
        <div className="form-buttons">
          <button type="submit" className="primary-btn">Register</button>
        </div>
      </form>
    </div>
  );
};

export default RegisterPage;
