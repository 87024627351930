import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css'; // Import custom CSS
import { fetchUserData, submitBooking } from '../api';

const BookOutPage = ({ user }) => {
  const [registration, setRegistration] = useState('');
  const [pilot, setPilot] = useState('');
  const [location, setLocation] = useState('');
  const [pax, setPax] = useState(1);
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Defaults to today's date
  const [time, setTime] = useState(new Date().toISOString().split('T')[1].substring(0, 5)); // Defaults to current time
  const [aircraft_type, setType] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is not a guest or admin before fetching user data
    if (user && user.token && user.role !== 'guest' && user.role !== 'admin') {
      const getUserData = async () => {
        try {
          const userData = await fetchUserData(user.token);
          
          // Prepopulate fields with current user data
          setPilot(`${userData.last_name || ''}`.trim());
          setRegistration(userData.registration?.toUpperCase() || '');
          setType(userData.aircraft_type || '');
        } catch (error) {
          console.error('Error fetching user data', error);
          alert('Failed to fetch user data');
        }
      };

      getUserData();
    } else {
      // If user is a guest or admin, you can either prepopulate some default values
      setPilot(''); // Or leave as empty
      setRegistration('');
      setType('');
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bookingData = {
      registration,
      aircraft_type,  // Make sure type is passed correctly
      pilot,
      pax,
      date,
      location,
      time,
      booking_type: 'Out',  // Specify booking type as 'out'
    };

    try {
      const response = await submitBooking(user.token, bookingData);

      // Check if booking was successful (status 201)
      if (response.status === 201) {      if (user.role === 'admin') {
        navigate('/admin-home');
      } else if (user.role === 'guest') {
        navigate('/guest-home');
      } else {
        navigate('/home'); // This is for non-admin, non-guest users
      }
      } else {
        // Handle unexpected response status
        alert('Booking failed: Unexpected response');
      }
    } catch (error) {
      // Log error details for debugging
      console.error('Error making booking:', error);

      // Show alert if booking failed
      alert('Booking failed: ' + (error.response ? error.response.data : error.message));
    }
  };

  const handleCancel = () => {
    // Go back to home page without making a booking
    navigate('/home');
  };

  return (
<div className="bookin-container">
  {/* Add your logo at the top */}
  <h1>Book Out</h1>
  <form onSubmit={handleSubmit} className="bookin-form">
    <div className="form-group">
      <input
        type="text"
        value={registration}
        onChange={(e) => setRegistration(e.target.value.toUpperCase())}
        placeholder="Registration"
        required
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        value={aircraft_type}
        onChange={(e) => setType(e.target.value)}
        placeholder="Aircraft Type"
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        value={pilot}
        onChange={(e) => setPilot(e.target.value)}
        placeholder="Pilot"
        required
      />
    </div>
    <div className="form-group">
      <input
        type="number"
        value={pax}
        onChange={(e) => setPax(Number(e.target.value))}
        min="1"
        placeholder="Passengers"
        required
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        placeholder="Destination"
        required
      />
    </div>
    <div className="form-group">
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        required
      />
    </div>
    <div className="form-group">
      <input
        type="time"
        value={time}
        onChange={(e) => setTime(e.target.value)}
        required
      />
    </div>
    {/* Buttons aligned at the bottom */}
    <div className="form-buttons">
      <button type="button" onClick={handleCancel} className="secondary-btn">
        Cancel
      </button>
      <button type="submit" className="primary-btn">
        Book Out
      </button>
    </div>
  </form>
</div>

  );
};

export default BookOutPage;
