import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import dayjs from 'dayjs';
import '../App.css';
import { getAllBookings } from '../api';

const AdminHomePage = ({ user, setUser, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [allBookings, setAllBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [search, setSearch] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
    setIsLoggedIn(false);
    navigate('/');
  };

  // Fetch all bookings
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const bookings = await getAllBookings(user.token);
        const sortedBookings = bookings.sort((a, b) => new Date(b.date) - new Date(a.date));
        setAllBookings(sortedBookings.slice(0, 100)); // Limit to last 100 bookings
        setFilteredBookings(sortedBookings.slice(0, 100));
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    if (user?.token) {
      fetchBookings();
    }
  }, [user.token]);

  // Handle search input change
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearch(searchTerm);
    setFilteredBookings(
      allBookings.filter((booking) =>
        ['pilot', 'registration', 'date'].some((field) =>
          booking[field]?.toLowerCase().includes(searchTerm)
        )
      )
    );
  };

  // Export filtered bookings to CSV
  const exportToCSV = () => {
    const csvContent = [
      ['In/Out', 'Date', 'Time', 'A/C Type', 'Registration', 'From / To', 'Pilot', 'Pax'],
      ...filteredBookings.map((booking) => [
        booking.booking_type,
        booking.date,
        booking.time,
        booking.aircraft_type,
        booking.registration,
        booking.location,
        booking.pilot,
        booking.pax,
      ]),
    ]
      .map((row) => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'bookings.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Handle edit dialog open/close
  const handleEdit = (booking) => {
    setSelectedBooking(booking);
    setEditDialogOpen(true);
  };

  const handleEditSave = () => {
    // Save updated booking logic here
    setEditDialogOpen(false);
    setSelectedBooking(null);
  };

  const handleEditCancel = () => {
    setEditDialogOpen(false);
    setSelectedBooking(null);
  };

  // Render booking card
  const renderBookingCard = (booking) => (
    <Card variant="outlined" sx={{ marginBottom: 2, padding: 1 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <Typography variant="subtitle2" color="textSecondary">In/Out</Typography>
            <Typography>{booking.booking_type}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant="subtitle2" color="textSecondary">Date</Typography>
            <Typography>{dayjs(booking.date).format('YY-MM-DD')}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle2" color="textSecondary">Time</Typography>
            <Typography>{booking.time}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant="subtitle2" color="textSecondary">A/C Type</Typography>
            <Typography>{booking.aircraft_type}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" color="textSecondary">Registration</Typography>
            <Typography>{booking.registration}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" color="textSecondary">From / To</Typography>
            <Typography>{booking.location}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" color="textSecondary">Pilot</Typography>
            <Typography>{booking.pilot}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle2" color="textSecondary">Pax</Typography>
            <Typography>{booking.pax}</Typography>
          </Grid>
        </Grid>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleEdit(booking)}
          sx={{ marginTop: 2 }}
        >
          Edit
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <div className="admin-home-container">
      <div className="admin-home-header">
        <h1 className="home-page-title">Admin Dashboard</h1>
        <div className="home-buttons">
          <Button variant="contained" color="primary" onClick={() => navigate('/book-in')}>
            Book In
          </Button>
          <Button variant="contained" color="primary" onClick={() => navigate('/book-out')}>
            Book Out
          </Button>
          <Button variant="contained" color="primary" onClick={() => navigate('/accounts')}>
            Accounts
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </div>

      <div className="search-container">
        <TextField
          label="Search Bookings"
          variant="outlined"
          fullWidth
          value={search}
          onChange={handleSearchChange}
        />
        <Button variant="contained" color="success" onClick={exportToCSV}>
          Export to CSV
        </Button>
      </div>

      <div className="bookings-container">
        {filteredBookings.map((booking, index) => (
          <React.Fragment key={index}>{renderBookingCard(booking)}</React.Fragment>
        ))}
      </div>

      <Dialog open={editDialogOpen} onClose={handleEditCancel}>
        <DialogTitle>Edit Booking</DialogTitle>
        <DialogContent>
          {/* Add input fields to edit booking details */}
          <Typography variant="body1">
            Edit functionality to be implemented here.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminHomePage;
