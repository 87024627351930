import axios from 'axios';

//const API_URL = "http://127.0.0.1:5000"; 
const API_URL = "https://popout.giles53716.com/api" // Update to match your backend URL

export const api = axios.create({
    baseURL: API_URL,
});


// Function to handle login
export const loginUser = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, { username, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch bookings function
export const fetchBookings = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/bookings`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;  // Return the data from the response
    } catch (error) {
      throw error;  // Propagate the error
    }
  };

 
// Fetch user data function
export const fetchUserData = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/account`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;  // Return user data
  } catch (error) {
    throw error;  // Propagate error
  }
};

// Submit booking function
export const submitBooking = async (token, bookingData) => {
  try {
    const response = await axios.post(`${API_URL}/booking`, bookingData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;  // Return response from the server
  } catch (error) {
    throw error;  // Propagate error
  }
};


// Register user function
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/register`, userData);
    return response;  // Return response from the server
  } catch (error) {
    throw error;  // Propagate error
  }
};

// Fetch all bookings function
export const getAllBookings = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/all-bookings`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data; // Return bookings data
  } catch (error) {
    throw error; // Propagate error
  }
};

// Fetch today's bookings function
export const getTodayBookings = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/bookingstoday`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data; // Return today's bookings data
  } catch (error) {
    throw error; // Propagate error
  }
};


// Fetch user data
export const getUserData = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/account`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error; // Propagate error
  }
};

// Submit a booking
export const createBooking = async (bookingData, token) => {
  try {
    const response = await axios.post(`${API_URL}/booking`, bookingData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    throw error; // Propagate error
  }
};


// Update user account details
export const updateUserAccount = async (userData, token) => {
  try {
    const response = await axios.put(`${API_URL}/account`, userData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    throw error; // Propagate error
  }
};

// Function to get all accounts (for the admin)
export const getAllAccounts = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/accounts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching accounts:', error);
    throw error;
  }
};

// Function to update a specific account (for the admin)
export const updateAccount = async (token, accountData) => {
  try {
    const response = await axios.put(`${API_URL}/accounts/${accountData.id}`, accountData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating account:', error);
    throw error;
  }
};







