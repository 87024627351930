import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllAccounts, updateAccount } from '../api'; // Backend API calls
import '../App.css'; // Styles

const AccountsPage = ({ user }) => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [search, setSearch] = useState('');

  // Fetch all accounts
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const data = await getAllAccounts(user.token);
        setAccounts(data);
        setFilteredAccounts(data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    if (user?.token) {
      fetchAccounts();
    }
  }, [user]);

  // Filter accounts by search
  const handleSearch = () => {
    const filtered = accounts.filter(
      (account) =>
        account.email.toLowerCase().includes(search.toLowerCase()) ||
        account.first_name.toLowerCase().includes(search.toLowerCase()) ||
        account.last_name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredAccounts(filtered);
  };

  // Handle account selection
  const handleSelectAccount = (account) => {
    setSelectedAccount(account);
  };

  // Handle form changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedAccount((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Save account changes
  const handleSave = async () => {
    try {
      await updateAccount(user.token, selectedAccount);
      alert('Account updated successfully!');
      setSelectedAccount(null);
    } catch (error) {
      console.error('Error updating account:', error);
      alert('Failed to update account.');
    }
  };

  return (
    <div className="accounts-page-container">
      <h1>Admin - Accounts Management</h1>
      <button onClick={() => navigate('/admin-home')}>Back to Dashboard</button>

      {/* Search Section */}
      <div className="accounts-search">
        <input
          type="text"
          placeholder="Search by email or name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>

      {/* Accounts Table */}
      <div className="accounts-table">
        <h2>Accounts</h2>
        {filteredAccounts.map((account) => (
          <div
            key={account.id}
            className="account-row"
            onClick={() => handleSelectAccount(account)}
          >
            <span>{account.email}</span>
            <span>{`${account.first_name} ${account.last_name}`}</span>
          </div>
        ))}
      </div>

      {/* Edit Account Section */}
      {selectedAccount && (
        <div className="edit-account">
          <h2>Edit Account</h2>
          <form>
            <label>Email: <input type="email" name="email" value={selectedAccount.email} onChange={handleInputChange} /></label>
            <label>First Name: <input type="text" name="first_name" value={selectedAccount.first_name} onChange={handleInputChange} /></label>
            <label>Last Name: <input type="text" name="last_name" value={selectedAccount.last_name} onChange={handleInputChange} /></label>
            <label>Aircraft Type: <input type="text" name="aircraft_type" value={selectedAccount.aircraft_type || ''} onChange={handleInputChange} /></label>
            <label>Registration: <input type="text" name="registration" value={selectedAccount.registration || ''} onChange={handleInputChange} /></label>
            <label>Membership Number: <input type="text" name="membership_number" value={selectedAccount.membership_number || ''} onChange={handleInputChange} /></label>
            <label>
              Is Current Member:
              <input type="checkbox" name="is_current_member" checked={selectedAccount.is_current_member} onChange={handleInputChange} />
            </label>
          </form>
          <button onClick={handleSave}>Save Changes</button>
        </div>
      )}
    </div>
  );
};

export default AccountsPage;
