import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import HomePage from './components/HomePage';
import BookInPage from './components/BookInPage';
import BookOutPage from './components/BookOutPage';
import AccountPage from './components/AccountPage';
import AdminHomePage from './components/AdminHomePage';
import GuestHomePage from './components/GuestHomePage';
import AccountsPage from './components/AccountsPage';


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setIsLoggedIn(true);
    }
  }, []);

  return (
<Router>
  <Routes>
    <Route
      path="/"
      element={isLoggedIn ? (
        user?.role === 'admin' ? (
          <Navigate to="/admin-home" />
        ) : user?.role === 'guest' ? (
          <Navigate to="/guest-home" />
        ) : (
          <Navigate to="/home" />
        )
      ) : (
        <LoginPage setIsLoggedIn={setIsLoggedIn} setUser={setUser} />
      )}
    />
    <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} setUser={setUser} />} />
    <Route path="/register" element={<RegisterPage />} />
    <Route
      path="/home"
      element={isLoggedIn && user?.role === 'user' ? <HomePage user={user} setIsLoggedIn={setIsLoggedIn} setUser={setUser} /> : <Navigate to="/login" />}
    />
    <Route path="/book-in" element={isLoggedIn ? <BookInPage user={user} /> : <Navigate to="/login" />} />
    <Route path="/book-out" element={isLoggedIn ? <BookOutPage user={user} /> : <Navigate to="/login" />} />
    <Route path="/account" element={isLoggedIn ? <AccountPage user={user} /> : <Navigate to="/login" />} />
    <Route path="/admin-home" element={isLoggedIn && user?.role === 'admin' ? <AdminHomePage user={user} setUser={setUser} setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/login" />} />
    <Route path="/accounts" element={isLoggedIn && user?.role === 'admin' ? <AccountsPage user={user} setUser={setUser} setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/login" />} />
    <Route path="/guest-home" element={isLoggedIn && user?.role === 'guest' ? <GuestHomePage user={user} setUser={setUser} setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/login" />} />
  </Routes>
</Router>

  );
};

export default App;
